const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Replace Lost Item',
      backgroundColor: 'rgba(244, 224, 77,1)',
      borderColor: 'rgba(244, 224, 77,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(244, 224, 77,0.5)',
      hoverBorderColor: 'rgba(244, 224, 77,0.8)',
      data: [6500, 4300, 5900, 8000, 8100, 5600, 5500, 4000]
    },
    {
      label: 'Replace Damaged Item',
      backgroundColor: 'rgba(242, 237, 111,1)',
      borderColor: 'rgba(242, 237, 111,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(242, 237, 111,0.5)',
      hoverBorderColor: 'rgba(242, 237, 111,0.8)',
      data: [5900, 3200, 8000, 8100, 5600, 5500, 4000, 6500]
    },
    {
      label: 'Replenish Stock',
      backgroundColor: 'rgba(206, 227, 151,1)',
      borderColor: 'rgba(206, 227, 151,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(206, 227, 151,0.5)',
      hoverBorderColor: 'rgba(206, 227, 151,0.8)',
      data: [3200, 8000, 8100, 5600, 5500, 4000, 6500, 5900]
    },
    {
      label: 'Support New Procedure',
      backgroundColor: 'rgba(141, 177, 171,1)',
      borderColor: 'rgba(141, 177, 171,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(141, 177, 171,0.5)',
      hoverBorderColor: 'rgba(141, 177, 171,0.8)',
      data: [8000, 8100, 5600, 5500, 4000, 6500, 5900, 3200]
    },
    {
      label: 'Support Care for more Patients',
      backgroundColor: 'rgba(88, 119, 146,1)',
      borderColor: 'rgba(88, 119, 146,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(88, 119, 146,0.5)',
      hoverBorderColor: 'rgba(88, 119, 146,0.8)',
      data: [8100, 5600, 5500, 4000, 6500, 5900, 3200, 8000]
    },
    {
      label: 'Other',
      backgroundColor: 'rgba(115, 148, 159,1)',
      borderColor: 'rgba(115, 148, 159,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(115, 148, 159,0.5)',
      hoverBorderColor: 'rgba(115, 148, 159,0.8)',
      data: [5600, 5500, 4000, 6500, 5900, 3200, 8000, 8100]
    }
  ]
};

export default data