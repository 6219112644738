import React from 'react';
import { Bar } from 'react-chartjs-2';

const getCurrency = number =>
  number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });

const ChartNumberAndDollar = ({ data }) => {
  return (
    <Bar
      data={data}
      width={1000}
      height={500}
      options={{
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              id: 'asdf',
              // type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  return getCurrency(value);
                }
              }
            },
            {
              id: 'fdsa',
              type: 'linear',
              position: 'right',
              ticks: {
                beginAtZero: true,
              }
            }
          ]
        },
        layout: {
          padding: {
            left: 50,
            right: 50,
            top: 0,
            bottom: 0
          }
        },
        legend: {
          position: 'top'
        }
      }}
    />
  );
};

export default ChartNumberAndDollar;
